<template>
  <div
    class="group__apply-container"
    :class="{
      forbidden
    }"
  >
    <div class="apply__inner">
      <div class="apply__main-body">
        <div class="body__header">
          <div class="header__item">账号</div>
          <div class="header__item">申请时间</div>
        </div>
        <div class="body__main-frame">
          <template v-if="applies && applies.length > 0">
            <div
              class="body__item"
              @click="onManageShow(apply)"
              v-for="(apply, index) in applies"
              :key="index"
            >
              <div class="item__info">{{ index + 1 }}</div>
              <div class="item__info cover">
                <img :src="apply.applicant.headimgurl" alt="" />
              </div>
              <div class="item__info">
                <p>{{ apply.applicant.nickname }}</p>
              </div>
              <div class="item__info createdAT">
                <p>
                  <span>{{ apply.created_at | parseTime("{y}.{m}.{d} {h}:{i}") }}</span>
                </p>
              </div>
              <div class="item__info">
                <svg-icon icon-class="icon-dots"></svg-icon>
              </div>
            </div>
          </template>
          <template v-else>
            <p style="text-align:center;padding:50px 0;">暂无请求</p>
          </template>
        </div>
      </div>
    </div>
    <!-- 标语 -->
    <slogan text="✖ 同伴的标志 ✖"></slogan>
    <van-button class="bt-back" @click="goBack">返回</van-button>
    <van-popup v-model="popUpShow" position="bottom" :close-on-click-overlay="false">
      <div class="apply__bts">
        <van-button class="bt__item green" :loading="doActioning === 'accept'" @click="onAccept"
          >通过</van-button
        >
        <van-button class="bt__item red" :loading="doActioning === 'refuse'" @click="onRefuse"
          >拒绝</van-button
        >
        <van-button class="bt__item" @click="onCancel">取消</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import forbiddenMixin from '@/mixins/forbidden';
import slogan from '../common/_slogan.vue';

export default {
  name: 'views__group-apply',
  props: ['groupID'],
  mixins: [forbiddenMixin],
  components: {
    slogan,
  },
  data() {
    return {
      requesting: false,
      applies: null,
      popUpShow: false,
      currenApply: null,
      doActioning: '',
    };
  },
  watch: {
    $route: {
      handler() {
        this.getmore();
      },
      immediate: true,
    },
  },
  mounted() {
    // console.log();
  },
  methods: {
    getmore() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        that.$request
          .get(`api/group/applies/${that.groupID}`)
          .then((res) => {
            const { list } = res.data;
            that.applies = list;
          })
          .catch((err) => {
            if (err.status === 403) {
              this.notifyForbidden('group', err.message, '查看小组', `/group/${that.groupID}`);
            }
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
    onManageShow(apply) {
      this.popUpShow = true;
      this.currenApply = apply;
    },
    onCancel() {
      this.doActioning = '';
      this.popUpShow = false;
      this.currenApply = null;
    },
    onAccept() {
      this.handleAction('accept');
    },
    onRefuse() {
      this.handleAction('refuse');
    },
    handleAction(result) {
      const that = this;
      if (that.doActioning === '') {
        that.doActioning = result;
        that.$request
          .get(`api/group/${that.groupID}/member/apply/${that.currenApply.id}`, {
            result,
          })
          .then((res) => {
            const { data } = res;
            that.applies = that.applies.filter((item) => Number(item.id) !== Number(data.id));
            that.$notify({
              type: 'success',
              message: '操作成功',
            });
          })
          .catch((err) => {
            that.$notify({
              message: err.message,
            });
          })
          .finally(() => {
            that.onCancel();
          });
      }
    },
  },
};
</script>

<style></style>
